<template>
  <v-card class="mx-auto" elevation="0">
    <base-material-card
      color="primary"
      width="2600"
      style="background-color: white"
      flat
      icon="mdi-dolly"
      title="Fornecedor/Cliente"
    >
      <v-card color flat class="px-4 mt-4">
        <v-card-title color="white" class="pt-0">
          <v-row justify="space-between" align="start">
            <v-col
              id="list-crud"
              cols="10"
              class="text-md-end pb-sm-0 text-sm-left py-sm-7 py-md-2"
            >
              <div class="filters-wrapper">
                <div class="in-line mr-1 hidden-md-and-down">
                  <span>Filtros:</span>
                </div>
                <div class="in-line mx-2">
                  <v-radio-group v-model="legalPersonFilterer" row>
                    <v-radio
                      v-for="(p, i) in persons"
                      :key="i"
                      :label="p.label"
                      :value="p.value"
                    ></v-radio>
                  </v-radio-group>
                </div>
                <div class="in-line mx-2">
                  <v-text-field v-model="nameFiltered" label="Nome" clearable />
                </div>
                <div class="in-line mx-2">
                  <agr-city-selector
                    v-model="cityFilterer"
                    label="Cidade"
                    clearable
                  />
                </div>
                <div class="in-line mx-2 hidden-sm-and-down">
                  <agr-mask-input
                    v-model="documentFilterer"
                    label="Documento"
                    :mask="['###.###.###-##', '##.###.###/####-##']"
                    clearable
                  />
                </div>
                <div class="in-line mx-2 hidden-sm-and-down">
                  <v-autocomplete
                    v-model="serviceFilterer"
                    :items="service_types"
                    label="Serviço"
                    item-text="name"
                    item-value="value"
                    :menu-props="{ maxHeight: 250 }"
                    clearable
                    auto-select-first
                  />
                </div>
              </div>
            </v-col>
            <v-col cols="2" class="text-sm-right mb-sm-4">
              <v-btn
                large
                class="py-sm-0 py-2 font-weight-medium"
                dark
                color="primary"
                rounded
                :disabled="disableAdd"
                @click="openForm"
              >
                <!-- Larges screens -->
                <span class="hidden-md-and-down">Adicionar novo</span>
                <!-- Smalls screens -->
                <span class="hidden-lg-and-up">Novo</span>
              </v-btn>
              <v-alert
                v-if="disableAdd"
                border="top"
                colored-border
                type="warning"
                elevation="2"
                class="pb-0"
              >
                Adicione uma propriedade antes.
              </v-alert>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row v-resize="onResize">
            <v-skeleton-loader
              v-if="load"
              v-bind="crudSkelletonAttrs"
              type="table-heading@2, list-item-two-line"
              width="100%"
            />
            <agr-data-table
              v-else
              :headers="headerFiltered"
              :items="providersFiltered"
              :loading="load"
              :btn-actions="true"
              resource="provider"
              @edit="edit"
              @confirmDelete="confirmDelete"
              @duplicate="duplicate"
            >
              <!-- Itens slots -->
              <template v-slot:name="{ item }">
                <span class="font-weight-medium">{{ item.name }}</span>
              </template>
              <template v-slot:tipo="{ item }">
                <span>{{ item.legal_person ? "Jurídica" : "Física" }}</span>
              </template>
              <template v-slot:document="{ item }">
                <span>{{ returnDocument(item) }}</span>
              </template>
              <template v-slot:phone_number="{ item }">
                <span>{{ returnPhone(item) }}</span>
              </template>
              <template v-slot:service="{ item }">
                <span>{{ returnServiceType(item) }}</span>
              </template>
            </agr-data-table>
          </v-row>
        </v-card-text>
      </v-card>
      <provider-form
        ref="form"
        v-model="dialog"
        :service_types="service_types"
        :gColor="'#066f6a'"
        @updateResourceList="updateResourceList(results.objects, $event)"
      />
    </base-material-card>
  </v-card>
</template>

<script>
import ProviderForm from "./ProviderForm";
import CrudMixins from "@/mixins/crud";

import { maskCNPJ, maskCPF, maskPhone } from '@/utilsText';

export default {
  name: "Provider",
  mixins: [CrudMixins],
  components: {
    ProviderForm,
  },
  data() {
    return {
      dialog: false,
      disableAdd: false,
      load: false,
      persons: [
        { label: "Pessoa Física", value: false },
        { label: "Pessoa Jurídica", value: true },
      ],
      service_types: [
        {
          name: "Empresa Interna",
          value: "INCOMPANY",
        },
        {
          name: "Banco",
          value: "BANK",
        },
        {
          name: "Corretora",
          value: "BROKER",
        },
      ],
      nameFilter: "",
      serviceFilterer: undefined,
      nameFiltered: undefined,
      cityFilterer: undefined,
      documentFilterer: undefined,
      legalPersonFilterer: undefined,
      headers: [
        {
          text: "Nome",
          tooltip: "Nome do fornecedor/cliente",
          align: "left",
          value: "name",
        },
        {
          text: "Pessoa",
          tooltip: "Qual o tipo de pessoa",
          align: "center",
          value: "tipo",
        },
        {
          text: "Documento",
          tooltip: "CPF ou CNPJ do fornecedor/cliente",
          align: "center",
          value: "document",
        },
        {
          text: "E-mail",
          tooltip: "E-mail do forncedor/cliente",
          align: "center",
          value: "email",
        },
        {
          text: "Telefone",
          tooltip: "Telefone do forncedor/cliente",
          align: "center",
          value: "phone_number",
        },
        {
          text: "Cidade",
          tooltip: "Cidade de origem do fornecedor/cliente",
          align: "center",
          value: "city.name",
        },
        {
          text: "Serviço",
          tooltip: "Tipo do fornecedor/cliente",
          align: "center",
          value: "service",
        },
        {
          text: "Ações",
          align: "center",
          tooltip: "Modificar ou excluir o fornecedor/cliente",
          value: "actions",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    providersFiltered() {
      // criteria definida no crud mixin
      if (!this.criteria.length) return this.results.objects;
      // propotype definido no crud mixin
      return this.results.objects.dynamicFilter(this.criteria);
    },
    headerFiltered() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return [
          this.headers[0],
          this.headers[1],
          this.headers[5],
          this.headers[this.headers.length - 1],
        ];
      }
      return this.headers;
    },
  },
  methods: {
    searchProviders() {
      let payload = {
        resource: "provider",
        params: {
          my: true,
          checkDelete: true,
        },
      };
      this.load = true;
      this.search(payload).then(() => {
        this.load = false;
      });
    },
    edit(uprovider) {
      this.$refs.form.toUpdate(uprovider);
    },
    confirmDelete(dprovider) {
      this.setResource("provider");
      dprovider.is_deleted = true;
      dprovider.deleted_date = new Date().toISOString();
      this.update(dprovider)
        .then(() => {
          this.$alertSuccess("Fornecedor/cliente deletedo com sucesso!");
          this.removeFromResourceList(this.results.objects, dprovider);
        })
        .catch(() => {
          this.$alertError(
            "Houve um problema ao deletar o fornecedor/cliente!"
          );
        });
    },
    returnDocument(item) {
      return item.legal_person
        ? maskCNPJ(item.document)
        : maskCPF(item.document);
    },
    returnPhone(item) {
      return maskPhone(item.phone_number);
    },
    returnServiceType(item) {
      let result = this.service_types.find((st) => st.value === item.service);
      return result ? result.name : "-";
    },
  },
  created() {
    this.searchProviders();
  },
  watch: {
    legalPersonFilterer(val) {
      // função do crud mixin
      this.createUpdateCriteria(val, "legal_person");
    },
    cityFilterer(val) {
      // função do crud mixin
      this.createUpdateCriteria(val, "city_id");
    },
    nameFiltered(val) {
      // função do crud mixin
      this.createUpdateCriteria(val, "name");
    },
    documentFilterer(val) {
      // função do crud mixin
      this.createUpdateCriteria(val, "document");
    },
    serviceFilterer(val) {
      // função do crud mixin
      this.createUpdateCriteria(val, "service");
    },
  },
};
</script>
