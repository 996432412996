<template>
  <v-dialog
    v-model="show"
    mix-height="90vh"
    max-width="40vw"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
    @keydown.esc="close"
  >
    <v-card pt-3>
      <v-card-title class="form-header headline pa-2 font-weight-medium">
        {{ componentsText.action }} Fornecedor/Cliente
      </v-card-title>
      <v-card-text>
        <v-form ref="mainForm">
          <v-row>
            <v-col cols="12">
              <v-radio-group v-model="providerType" row>
                <v-radio
                  v-for="(p, i) in persons"
                  :key="i"
                  :label="p.label"
                  :value="p.value"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row v-if="providerType === 'cpf'">
            <v-col cols="12">
              <v-text-field
                v-model="provider.name"
                label="Nome*"
                :rules="[rules.required]"
                required
              />
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="6">
              <v-text-field
                v-model="provider.name"
                label="Nome*"
                :rules="[rules.required]"
                required
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="provider.company_name"
                label="Razão Social"
                required
              />
            </v-col>
          </v-row>
          <v-row justify="start">
            <v-col class="text-left">
              <v-btn
                rounded
                small
                class="text-button"
                :color="extraInfo ? 'error' : 'primary'"
                @click="extraInfo = !extraInfo">
                <v-icon left>
                  {{ extraInfo ? 'mdi-close' : 'mdi-plus' }}
                </v-icon>
                {{ extraInfo ? 'fechar' : 'informações adicionais'}}
              </v-btn>
            </v-col>
          </v-row>
          <v-expand-transition appear>
            <div v-if="extraInfo">
              <v-row>
                <v-col cols="6">
                  <agr-mask-input
                    v-if="providerType === 'cpf'"
                    v-model="provider.document"
                    label="CPF"
                    mask="###.###.###-##"
                    :rules="[rules.cpf]"
                  />
                  <agr-mask-input
                    v-else
                    v-model="provider.document"
                    label="CNPJ"
                    mask="##.###.###/####-##"
                    :rules="[rules.cnpj]"
                  />
                </v-col>
                <v-col cols="6">
                  <agr-mask-input
                    v-model="provider.phone_number"
                    label="Fone/Whatsapp"
                    :mask="['(##) #### ####', '(##) ##### ####']"
                    :rules="[rules.phone]"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <agr-city-selector
                    v-model="provider.city_id"
                    label="Cidade"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="provider.email"
                    label="Email"
                    :rules="[rules.email]"
                    required
                  />
                </v-col>
              </v-row>
            </div>
          </v-expand-transition>
          <v-row>
            <v-col cols="6">
              <v-autocomplete
                v-model="provider.service"
                label="Serviço"
                :items="service_types"
                item-text="name"
                item-value="value"
                clearable
                required
              />
            </v-col>
            <v-col cols="6">
              <v-scroll-x-transition>
                <v-autocomplete
                  v-if="inCompanySelected"
                  v-model="provider.currency_id"
                  label="Moeda*"
                  :items="currencies"
                  item-text="name"
                  item-value="id"
                  :rules="[rules.required]"
                  required
                />
              </v-scroll-x-transition>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row justify="end" no-gutters>
          <v-col class="text-right firefox-align-end">
            <v-btn color="error" @click="close"> Cancelar </v-btn>
            <v-btn color="primary" @click="save">
              {{ componentsText.action }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FormMixins from "@/mixins/form";
import { maskCNPJ, maskCPF, maskPhone, unmaskCNPJ, unmaskCPF, unmaskPhone } from '@/utilsText';
import { pick } from '@/utilsObject';

export default {
  name: "ProviderForm",
  props: {
    service_types: Array,
  },
  mixins: [FormMixins],
  data() {
    return {
      // obj principal do form
      provider: undefined,
      // define se mostra informações extra
      extraInfo: false,
      // forma padrão para o obj principal
      defaultProvider: {
        name: undefined,
        legal_person: false,
        company_name: undefined,
        email: undefined,
        service: undefined,
        city_id: undefined,
        currency_id: undefined,
        document: undefined,
        phone_number: undefined,
      },
      // radio btn para tipo de pessoa
      providerType: "cpf",
      // opções do tipo de pessoa
      persons: [
        { label: "Pessoa Física", value: "cpf" },
        { label: "Pessoa Jurídica", value: "cnpj" },
      ],
    };
  },
  methods: {
    save() {
      if (this.validate()) {
        this.provider.is_deleted = false;
        this.provider.user_id = this.user.id;
        if (this.providerType === "cpf") {
          this.provider.legal_person = false;
          this.provider.document = unmaskCPF(
            this.provider.document
          ); // remove mask
        } else {
          this.provider.legal_person = true;
          this.provider.document = unmaskCNPJ(
            this.provider.document
          ); // remove mask
        }
        this.provider.phone_number = unmaskPhone(
          this.provider.phone_number
        ); // remove blank spaces
        this.setResource("provider");
        this.saveResource(this.provider)
          .then(response => {
            this.$alertSuccess(this.componentsText.mSuccess);
            this.$emit("updateResourceList", response);
            this.close();
          })
          .catch(() => {
            this.$alertError(this.componentsText.mError);
          });
      }
    },
    initialize() {
      this.providerType = "cpf";
      this.extraInfo = false;
      this.$nextTick(() => {
        this.provider = Object.assign({}, this.defaultProvider);
      });
    },
    // verifica se existe
    // informação extra preenchida
    hasExtraInfo() {
      let extra = pick(
        this.provider,
        ['company_name', 'email', 'city_id', 'document', 'phone_number']
      )
      for (var prop in extra) {
        if(extra[prop])
          return true;
      }
      return false;
    },
    toUpdate(item) {
      this.show = true;
      this.$nextTick(() => {
        this.provider = Object.assign({}, item);
        if(this.hasExtraInfo()) {
          this.extraInfo = true;
          if (this.provider.legal_person) {
            this.providerType = "cnpj";
            this.provider.document = maskCNPJ(this.provider.document);
          } else {
            this.provider.document = maskCPF(this.provider.document);
          }
          this.provider.phone_number = maskPhone(this.provider.phone_number);
        }
      });
    },
  },
  computed: {
    componentsText() {
      if (this.provider && this.provider.id) {
        return {
          action: "Atualizar",
          mSuccess: "Fornecedor/cliente atualizado com sucesso!",
          mError: "Houve um problema ao atualizar o Fornecedor/cliente",
        };
      } else {
        return {
          action: "Adicionar",
          mSuccess: "Fornecedor/cliente inserido com sucesso!",
          mError: "Houve um problema ao inserir o Fornecedor/cliente",
        };
      }
    },
    inCompanySelected() {
      return this.provider.service && this.provider.service === "INCOMPANY"
        ? true
        : false;
    },
  },
  created() {
    this.provider = Object.assign({}, this.defaultProvider);
  },
};
</script>

<style>
</style>